import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AuthActions } from '.';
import { AppState } from '../app.state';
import { AuthStoreModule } from './auth-store.module';
import { B2BAccountManagerGuard } from './guards/b2b-account-manager.guard';
import { OperatorGuard } from './guards/operator.guard';
import { SupportAgentGuard } from './guards/support-agent.guard';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    AuthStoreModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
  ],
  exports: [LoginComponent],
  providers: [OperatorGuard, SupportAgentGuard, B2BAccountManagerGuard],
})
export class AuthModule {
  constructor(store: Store<AppState>, auth: Auth) {
    authState(auth).subscribe(user => {
      store.dispatch(
        AuthActions.userInfoChanged({
          userInfo: user
            ? {
                displayName: user.displayName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                providerId: user.providerId,
                uid: user.uid,
              }
            : null,
        }),
      );
      if (user) {
        user.getIdToken(true).then(token => store.dispatch(AuthActions.idTokenChanged({ idToken: token })));
      } else {
        store.dispatch(AuthActions.idTokenChanged({ idToken: null }));
      }
    });
  }
}
