import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { mapEntityDeltaChangeToEntityToHtml } from 'src/app/shared/mappings/entity-deltas.mapping';
import { EntityDeltaVM } from 'src/app/shared/models/entity-delta.vm.model';

@Component({
  selector: 'app-partner-change',
  standalone: true,
  imports: [],
  templateUrl: './partner-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerChangeComponent implements OnInit {
  @Input() change: EntityDeltaVM;
  mappedEntityDeltasHtml: string;
  constructor() {}

  readonly companyTypeMappings = [
    { id: 1, name: 'Viggo' },
    { id: 2, name: 'Other' },
  ];

  ngOnInit(): void {
    const newChanges = this.appendMappingDataToChanges();

    this.change = {
      ...this.change,
      changes: newChanges,
    };

    this.mappedEntityDeltasHtml = mapEntityDeltaChangeToEntityToHtml(this.change);
  }

  private appendMappingDataToChanges() {
    return this.change.changes.map(change => {
      if (change.name === 'CompanyType') {
        return {
          ...change,
          mappingData: this.companyTypeMappings,
        };
      }
      return change;
    });
  }
}
