import { ActionReducerMap } from '@ngrx/store';
import * as fromB2BAccountManager from 'projects/b2b-account-manager/src/app/store/b2b-account-manager.state';
import * as fromOperator from 'projects/operator/src/app/store/operator.state';
import * as fromSupportAgent from 'projects/support-agent/src/app/store/support-agent.state';
import * as fromAuth from './auth/auth.state';
import * as fromFinanceManager from './finance-manager/finance-manager.state';
import * as fromEntityLogs from './entity-logs/store/entity-logs.state';

export interface AppState
  extends fromOperator.OperatorState,
    fromB2BAccountManager.B2BAccountManagerState,
    fromSupportAgent.SupportAgentState,
    fromFinanceManager.FinanceManagerState {
  auth: fromAuth.AuthState;
  entityLogs: fromEntityLogs.EntityLogsState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  entityLogs: fromEntityLogs.reducer,
  ...fromOperator.reducers,
  ...fromSupportAgent.reducers,
  ...fromB2BAccountManager.reducers,
  ...fromFinanceManager.reducers,
};
