import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { AuthActions, AuthSelectors } from '..';
import { AuthModule } from '../auth.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AuthModule, MatIconModule, MatMenuModule, MatButtonModule, NgOptimizedImage],
})
export class UserComponent implements OnInit {
  photoUrl$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.photoUrl$ = this.store.select(AuthSelectors.userPhotoUrlSelector);
  }

  onLogoutButtonClick() {
    this.store.dispatch(AuthActions.logoutButtonClicked());
  }
}
