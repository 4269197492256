import { ActionReducerMap } from '@ngrx/store';
import * as fromAreas from '../areas/store/areas.state';
import * as fromAuth from '../auth/store/auth.state';
import * as fromDispatchMatches from '../dispatch-matches/store/dispatch-matches.state';
import * as fromDispatchQueueZones from '../dispatch-queue-zones/store/dispatch-queue-zones.state';
import * as fromDrivers from '../drivers/store/drivers.state';
import * as fromExternalFees from '../external-fees/store/external-fees.state';
import * as fromFlatRates from '../flat-rates/store/flat-rates.state';
import * as fromLiveMap from '../live-map/store/live-map.state';
import * as fromMap from '../map/store/map.state';
import * as fromMaxEtaSchedules from '../max-eta-schedules/store/max-eta-schedules.state';
import * as fromPartnerAdjustments from '../partner-adjustments/store/partner-adjustments.state';
import * as fromPartners from '../partners/store/partners.state';
import * as fromPlatformSettings from '../platform-settings/store/platform-settings.state';
import * as fromPolygons from '../polygons/store/polygons.state';
import * as fromProductFeatures from '../product-features/store/product-featues.state';
import * as fromProductPackages from '../product-packages/store/product-packages.state';
import * as fromProductVehicleTypes from '../product-vehicle-types/store/product-vehicle-types.state';
import * as fromFirestore from '../store/firestore/firestore.state';
import * as fromTariffs from '../tariffs/store/tariffs.state';
import * as fromTranslations from '../translations/store/translations.state';
import * as fromTrips from '../trips/store/trips.state';
import * as fromVatRates from '../vat-rates/store/vat-rates.state';
import * as fromVehicles from '../vehicles/store/vehicles.state';
export interface OperatorState {
  trips: fromTrips.TripsState;
  operatorAuthState: fromAuth.AuthState;
  drivers: fromDrivers.DriversState;
  firestore: fromFirestore.FirestoreState;
  partners: fromPartners.PartnersState;
  vehicles: fromVehicles.VehiclesState;
  areas: fromAreas.AreasState;
  polygons: fromPolygons.PolygonsState;
  flatRates: fromFlatRates.FlatRatesState;
  tariffs: fromTariffs.TariffsState;
  productFeatures: fromProductFeatures.ProductFeaturesState;
  productPackages: fromProductPackages.ProductPackagesState;
  map: fromMap.MapState;
  translations: fromTranslations.TranslationsState;
  vatRates: fromVatRates.VatRatesState;
  maxEtaSchedules: fromMaxEtaSchedules.MaxEtaSchedulesState;
  dispatchMatches: fromDispatchMatches.DispatchMatchesState;
  partnerAdjustments: fromPartnerAdjustments.PartnerAdjustmentsState;
  platformSettings: fromPlatformSettings.PlatformSettingsState;
  liveMap: fromLiveMap.LiveMapState;
  productVehicleTypes: fromProductVehicleTypes.ProductVehicleTypesState;
  dispatchQueueZones: fromDispatchQueueZones.DispatchQueueZonesState;
  externalFees: fromExternalFees.ExternalFeesState;
}

export const reducers: ActionReducerMap<OperatorState> = {
  trips: fromTrips.reducer,
  operatorAuthState: fromAuth.reducer,
  drivers: fromDrivers.reducer,
  firestore: fromFirestore.reducer,
  partners: fromPartners.reducer,
  vehicles: fromVehicles.reducer,
  areas: fromAreas.reducer,
  polygons: fromPolygons.reducer,
  flatRates: fromFlatRates.reducer,
  tariffs: fromTariffs.reducer,
  productFeatures: fromProductFeatures.reducer,
  productPackages: fromProductPackages.reducer,
  map: fromMap.reducer,
  translations: fromTranslations.reducer,
  vatRates: fromVatRates.reducer,
  maxEtaSchedules: fromMaxEtaSchedules.reducer,
  dispatchMatches: fromDispatchMatches.reducer,
  partnerAdjustments: fromPartnerAdjustments.reducer,
  platformSettings: fromPlatformSettings.reducer,
  liveMap: fromLiveMap.reducer,
  productVehicleTypes: fromProductVehicleTypes.reducer,
  dispatchQueueZones: fromDispatchQueueZones.reducer,
  externalFees: fromExternalFees.reducer,
};
