import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, EventType, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../app.state';
import { AuthSelectors } from '../auth';
import { BreadcrumbItem } from '../shared/models/menu-item.model';
import { RoutingService } from '../shared/services/routing.service';
import { whenPageVisible } from '../shared/utils/when-page-visible';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [CommonModule, AppRoutingModule],
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: BreadcrumbItem[];
  appName = 'Viggo Console';
  isHandset$: Observable<boolean>;

  constructor(
    private routingService: RoutingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.isHandset$ = this.store.select(AuthSelectors.isHandsetSelector);
    this.router.events
      .pipe(
        whenPageVisible(),
        withLatestFrom(this.store.select(AuthSelectors.hasAnyRoleSelector)),
        filter(
          ([event, hasAnyRole]) =>
            hasAnyRole &&
            ((event.type === EventType.Scroll && event.routerEvent instanceof NavigationEnd) ||
              event instanceof NavigationEnd),
        ),
        map(() => (this.breadcrumbs = this.routingService.createBreadcrumbs(this.activatedRoute.root))),
        map(() =>
          this.titleService.setTitle(this.routingService.createPageTitle(this.activatedRoute.root, this.appName)),
        ),
      )
      .subscribe();
  }
}
