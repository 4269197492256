<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="side" [opened]="this.isSideNavOpened$ | async" [mode]="this.sideNavMode$ | async">
    <app-nav></app-nav>
  </mat-sidenav>
  <mat-sidenav class="log-sidenav" [opened]="this.isEntityLogsVisible$ | async" mode="side" position="end">
    <app-entity-logs></app-entity-logs>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="header flex-row flex-space-around-center">
      <button mat-icon-button (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
      <app-breadcrumbs></app-breadcrumbs>
      <span class="navbar-spacer"></span>
      <app-area-select></app-area-select>
      <app-user></app-user>
    </div>
    <mat-divider></mat-divider>
    @if (isAuthorized$ | async) {
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    } @else {
      <div class="flex-row flex-center-center unauthorized-container">
        <mat-card class="flex-row">
          <mat-icon>error_outline</mat-icon>
          <span>Looks like you have not been assigned any roles yet. Please contact the administrator.</span>
        </mat-card>
      </div>
    }
    <ng-template #unauthorized>
      <div class="flex-row flex-center-center unauthorized-container">
        <mat-card class="flex-row">
          <mat-icon>error_outline</mat-icon>
          <span>Looks like you have not been assigned any roles yet. Please contact the administrator.</span>
        </mat-card>
      </div>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>
