import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions } from './auth';

@Injectable({ providedIn: 'root' })
export class RootErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): any {
    console.error(error);

    const router = this.injector.get(Router);
    const store = this.injector.get(Store);
    store.dispatch(
      AuthActions.errorEventFetched({
        state: {
          url: router.url,
          message: error.stack ? error.stack : error.message,
        },
      }),
    );

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
