@if ((isHandset$ | async) !== true) {
  <ul class="breadcrumb">
    @for (breadcrumb of breadcrumbs; track breadcrumb.url; let i = $index; let first = $first; let last = $last) {
      <li>
        @if (i === 0 || (i > 0 && last)) {
          <span class="breadcrumb-muted">
            {{ breadcrumb.title }}
            @if (last && breadcrumb.id) {
              <span> - {{ breadcrumb.id }}</span>
            }
          </span>
        }
        @if (i > 0 && !last) {
          <a [routerLink]="[breadcrumb.url]">{{ breadcrumb.title }}</a>
        }
      </li>
    }
  </ul>
}
