import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';
import { mergeMap, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { retryDelayFunction } from '../utils/api.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private auth = inject(Auth);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.includes(environment.financeManager?.elasticApi) &&
      req.url.includes(environment.financeManager.elasticIndexPrefix)
    ) {
      return this.addApiKey(req, next, environment.financeManager.elasticApiKey);
    } else if (
      req.url.includes(environment.support?.elasticApi) &&
      req.url.includes(environment.support.elasticIndexPrefix)
    ) {
      return this.addApiKey(req, next, environment.support.elasticApiKey);
    } else if (req.url.startsWith(environment.api) && !req.url.includes('signin')) {
      return this.addUserToken(req, next);
    } else {
      return next.handle(req);
    }
  }

  addApiKey(req: HttpRequest<any>, next: HttpHandler, key: string): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Authorization: `ApiKey ${key}`,
        'Content-Type': 'application/json',
      },
    });
    return next.handle(req).pipe(
      retry({
        count: 6,
        delay: retryDelayFunction({
          maxRetryAttempts: 6,
          scalingDuration: 1000,
          excludedStatusCodes: [404],
        }),
      }),
    );
  }

  addUserToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.auth.currentUser.getIdToken(true)).pipe(
      mergeMap(token => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(req);
      }),
    );
  }
}
