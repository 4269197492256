<div class="nav-component-container">
  <div class="header flex-row flex-center-center" [routerLink]="['/']">
    <img alt="favicon" ngSrc="assets/images/favicon.png" height="48" width="48" />
    <span class="mat-h1">Viggo</span>
  </div>
  <mat-divider></mat-divider>
  <mat-accordion multi="true" hideToggle="true" displayMode="flat">
    @if (isOperatorVisible()) {
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="isOperatorActive()">
        <mat-expansion-panel-header>Operator</mat-expansion-panel-header>
        <div class="menu">
          <ul>
            @for (item of operatorMenu(); track item.name) {
              <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
            }
            @if (isLevel1() === false) {
              @for (item of operatorLevel1Menu(); track item.name) {
                <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
              }
            }
            @if (isLevel1() === false && isLevel2() === false) {
              @for (item of operatorLevel2Menu(); track item.name) {
                <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
              }
            }
          </ul>
        </div>
      </mat-expansion-panel>
    }
    <mat-divider></mat-divider>
    @if (isSupportAgentVisible()) {
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="isSupportAgentActive()">
        <mat-expansion-panel-header>Support agent</mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="menuListTemplate; context: { list: supportAgentMenu() }"></ng-container>
      </mat-expansion-panel>
    }
    <mat-divider></mat-divider>
    @if (isB2bAccountManagerVisible()) {
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="isB2bAccountManagerActive()">
        <mat-expansion-panel-header>B2B account manager</mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="menuListTemplate; context: { list: b2bAccountManagerMenu() }"></ng-container>
      </mat-expansion-panel>
    }
    <mat-divider></mat-divider>
    @if (isFinanceManagerVisible()) {
      <mat-expansion-panel
        #rla="routerLinkActive"
        [@.disabled]="true"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.mat-elevation-z0]="true"
        [expanded]="isFinanceManagerActive()"
        routerLinkActive="active"
        class="no-padding-panel">
        <mat-expansion-panel-header>Finance manager</mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="menuListTemplate; context: { list: financeManagerMenu() }"></ng-container>
      </mat-expansion-panel>
    }
    <mat-divider></mat-divider>
  </mat-accordion>
</div>
<ng-template #menuListTemplate let-list="list">
  <div class="menu">
    <ul>
      @for (item of list; track item.name) {
        <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
      }
    </ul>
  </div>
</ng-template>
<ng-template #menuItemTemplate let-item="item">
  <li>
    <a [routerLink]="item.route" routerLinkActive="active-link" class="icon-text">
      <i class="material-icons">{{ item.icon }}</i>
      {{ item.name }}
    </a>
  </li>
</ng-template>
