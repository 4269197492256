import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { breakpointObserverChanged } from 'src/app/auth/auth.actions';
import { EntityChangesActions } from 'src/app/entity-logs/store';
import { whenPageVisible } from '../utils/when-page-visible';

@Injectable({
  providedIn: 'root',
})
export class WindowSizeService {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store,
  ) {}

  watchScreenSize() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.Handset])
      .pipe(
        whenPageVisible(),
        distinctUntilChanged((prev, curr) => prev.matches === curr.matches),
      )
      .subscribe(result => {
        const isHandset = result.matches;
        this.store.dispatch(breakpointObserverChanged({ isHandset }));
        if (isHandset) {
          this.store.dispatch(EntityChangesActions.entityChangeLogClosed());
        }
      });
  }
}
