import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { reducers } from './app.state';
import { AppEffects } from './store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateImmutability: true,
        strictStateSerializability: false,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EffectsModule.forRoot([AppEffects]),
  ],
  providers: [],
})
export class AppStoreModule {}
