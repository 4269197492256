@if (this.isVisible$ | async) {
  <button class="area-filter-button" mat-stroked-button [matMenuTriggerFor]="menu">
    <div class="text">{{ this.areaNames$ | async | slice: 0 : 20 }}</div>
    <mat-icon iconPositionEnd>map</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ul>
      @for (area of areas$ | async; track area.id) {
        <li>
          <mat-checkbox [(ngModel)]="checkedStatus[area.id]" [value]="area.id" (click)="$event.stopPropagation()">{{
            area.name
          }}</mat-checkbox>
        </li>
      }
    </ul>
    <button class="apply-button" mat-flat-button color="primary" (click)="onClickApply()">Apply</button>
  </mat-menu>
}
