<mat-card>
  <mat-card-title>
    <button mat-icon-button (click)="onCloseButtonClick()">
      <mat-icon>close</mat-icon>
    </button>
    Change history
  </mat-card-title>
  <mat-card-content>
    @if (isLoading$ | async) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }

    <table class="log-table">
      <tbody>
        @for (change of changes$ | async; track change.id) {
          <tr class="border-bottom">
            <td>
              <mat-icon class="log-icon">{{ change.icon.toString() }}</mat-icon>
            </td>
            <td>
              <div class="setting-change-line">
                @if (change.entityName === 'vehicle-service') {
                  <app-vehicle-change [change]="change"></app-vehicle-change>
                }
                @if (
                  change.entityName === 'partner-company' ||
                  change.entityName === 'partner-vehicleinsurance' ||
                  change.entityName === 'partner-liabilityinsurance' ||
                  change.entityName === 'partner-occupationalinsurance' ||
                  change.entityName === 'partner-transportlicense' ||
                  change.entityName === 'partner-contract'
                ) {
                  <app-partner-change [change]="change"></app-partner-change>
                }
                @if (change.entityName === 'driver-service' || change.entityName === 'account-user') {
                  <app-driver-change [change]="change"></app-driver-change>
                }
              </div>
              <div class="setting-metadata">
                <span class="log-user">{{ change.userName }}</span>
                <span class="log-date">{{ change.date | customdate }}</span>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>

    @if (!paginationDisabled()) {
      @if (page$ | async; as page) {
        <mat-paginator
          [length]="page.length"
          [pageSize]="page.pageSize"
          [pageIndex]="page.pageIndex"
          (page)="handlePage($event)"></mat-paginator>
      }
    }
  </mat-card-content>
</mat-card>
