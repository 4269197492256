import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { B2BAccountManagerGuard } from './auth/guards/b2b-account-manager.guard';
import { FinanceManagerGuard } from './auth/guards/finance-manager.guard';
import { OperatorGuard } from './auth/guards/operator.guard';
import { SupportAgentGuard } from './auth/guards/support-agent.guard';

const routes: Routes = [
  {
    path: 'operator',
    canMatch: [() => inject(OperatorGuard).hasOperatorRole()],
    loadChildren: () => import('../../projects/operator/src/app/app.module').then(m => m.OperatorModule),
    data: { title: 'Operator' },
  },
  {
    path: 'support-agent',
    canMatch: [() => inject(SupportAgentGuard).hasSupportAgentRole()],
    loadChildren: () => import('../../projects/support-agent/src/app/app.module').then(m => m.SupportAgentModule),
    data: { title: 'Support agent' },
  },
  {
    path: 'b2baccountmanager',
    canMatch: [() => inject(B2BAccountManagerGuard).hasB2BAccountManagerRole()],
    loadChildren: () =>
      import('../../projects/b2b-account-manager/src/app/app.module').then(m => m.B2BAccountManagerModule),
    data: { title: 'B2B account manager' },
  },
  {
    path: 'finance-manager',
    canMatch: [() => inject(FinanceManagerGuard).hasFinanceManagerRole()],
    loadChildren: () => import('./finance-manager/finance-manager.routes'),
    data: { title: 'Finance manager' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
