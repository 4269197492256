import { ApplicationRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { createEffect } from '@ngrx/effects';
import { concat, filter, first, interval, of, switchMap, tap } from 'rxjs';
import { NotificationService } from '../shared/services/notification.service';

@Injectable()
export class AppEffects {
  constructor(
    private swUpdate: SwUpdate,
    private notificationService: NotificationService,
    private appRef: ApplicationRef,
    private router: Router,
  ) {}

  everySixHoursOnceAppIsStable$ = createEffect(
    () => {
      if (!this.swUpdate.isEnabled) {
        return of();
      }

      return concat(
        this.appRef.isStable.pipe(first(isStable => isStable === true)), // when app is stable
        interval(6 * 60 * 60 * 1000), // every six hours
      ).pipe(
        switchMap(() => {
          try {
            return this.swUpdate.checkForUpdate();
          } catch (err) {
            console.error(err);
            return [];
          }
        }),
      );
    },
    { dispatch: false },
  );

  versionUpdates$ = createEffect(
    () => {
      if (!this.swUpdate.isEnabled) {
        return of();
      }

      return this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        switchMap(() => this.notificationService.info('A new version is available.', 'Reload')),
        tap(() => {
          this.router.navigate(['/']).then(() => {
            document.location.reload();
          });
        }),
      );
    },
    { dispatch: false },
  );

  unrecoverableUpdate$ = createEffect(
    () => {
      if (!this.swUpdate.isEnabled) {
        return of();
      }

      return this.swUpdate.unrecoverable.pipe(
        switchMap(() => this.notificationService.error('An error occurred that we cannot recover from.', 'Reload')),
        tap(() => {
          this.router.navigate(['/']).then(() => {
            document.location.reload();
          });
        }),
      );
    },
    { dispatch: false },
  );
}
