<div class="user-component-container">
  <div class="account-image" [matMenuTriggerFor]="menu">
    @if (photoUrl$ | async; as photoUrl) {
      <img class="account-circle" [src]="photoUrl" alt="Account" />
    } @else {
      <mat-icon class="account-circle">account_circle</mat-icon>
    }
  </div>
  <mat-menu #menu="matMenu">
    <button class="log-out-menu-item" mat-menu-item (click)="onLogoutButtonClick()">
      <span color="warn">Log out</span>
    </button>
  </mat-menu>
</div>
