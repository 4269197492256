import { Injectable } from '@angular/core';
import {
  Auth,
  idToken,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from '@angular/fire/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GoogleAuthProvider } from 'firebase/auth';
import { merge } from 'rxjs';
import { filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthActions, AuthSelectors } from '.';
import { AppState } from '../app.state';
import { ConsoleFirestoreService } from '../shared/services/console-firestore.service';
import { NotificationService } from '../shared/services/notification.service';
import { BrowserStorageService } from '../shared/services/website-storage.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private auth: Auth,
    private notificationService: NotificationService,
    private browserStorageService: BrowserStorageService,
    private consoleFirestoreService: ConsoleFirestoreService,
    private store: Store<AppState>,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginButtonClicked),
      switchMap(action =>
        signInWithEmailAndPassword(this.auth, action.email, action.password).catch(_ => {
          this.notificationService.error('Email or password is incorrect');
          return null;
        }),
      ),
      mergeMap(_ => []),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPasswordButtonClicked),
      switchMap(action =>
        sendPasswordResetEmail(this.auth, action.email)
          .then(_ => this.notificationService.success('Check your email to reset your password'))
          .catch(_ => this.notificationService.error('Email is incorrect or empty')),
      ),
      mergeMap(_ => []),
    ),
  );

  idTokenExpired = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.idTokenExpired),
      switchMap(() => idToken(this.auth).pipe(map(token => AuthActions.idTokenChanged({ idToken: token })))),
    ),
  );

  googleSigninClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.googleSignInClicked),
      switchMap(_ => signInWithPopup(this.auth, new GoogleAuthProvider())),
      mergeMap(_ => []),
    ),
  );

  isAreaFilterEnabled$ = createEffect(() =>
    this.store.select(AuthSelectors.isAreaFilterEnabledSelector).pipe(
      filter(hasAnyRole => hasAnyRole),
      switchMap(_ => this.getAreas()),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutButtonClicked),
      switchMap(_ => signOut(this.auth)),
      mergeMap(_ => []),
    ),
  );

  userAreasChangeApplyClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userAreasChangeApplyClicked),
      switchMap(action => this.browserStorageService.setUserAreaIds(action.selectedAreaIds)),
      tap(() => {
        window.location.reload();
      }),
      mergeMap(_ => []),
    ),
  );

  userInfoChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userInfoChanged),
      filter(action => !!action.userInfo),
      switchMap(_ => merge(this.getUserAreaIds(), this.getAllBrowserStorageItems(), this.watchAppConfig())),
    ),
  );

  private watchAppConfig() {
    return this.consoleFirestoreService.watchAppConfig().pipe(
      takeUntil(this.actions$.pipe(ofType(AuthActions.userInfoChanged))),
      map(appConfig => AuthActions.appConfigChanged({ appConfig })),
    );
  }

  private getUserAreaIds() {
    return this.browserStorageService
      .getUserAreaIds()
      .pipe(map(userAreaIds => AuthActions.userAreasFetched({ userAreaIds })));
  }

  private getAllBrowserStorageItems() {
    return this.browserStorageService.getAllItems().pipe(map(item => AuthActions.browserStorageItemFetched({ item })));
  }

  private getAreas() {
    return this.consoleFirestoreService.listAreas().pipe(map(areas => AuthActions.areasFetched({ areas })));
  }
}
