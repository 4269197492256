import { ActionReducerMap } from '@ngrx/store';
import * as fromBusinesses from '../businesses/store/businesses.state';
import * as fromDrivers from '../drivers/store/drivers.state';
import * as fromRiders from '../riders/store/riders.state';
import * as fromFirestore from '../store/firestore/firestore.state';
import * as fromTrips from '../trips/store/trips.state';

export interface SupportAgentState {
  firestoreState: fromFirestore.FirestoreState;
  ridersState: fromRiders.RidersState;
  tripsState: fromTrips.SupportAgentTripsState;
  driversState: fromDrivers.SupportAgentDriversState;
  businessesState: fromBusinesses.SupportAgentBusinessesState;
}

export const reducers: ActionReducerMap<SupportAgentState> = {
  firestoreState: fromFirestore.reducer,
  ridersState: fromRiders.reducer,
  tripsState: fromTrips.reducer,
  driversState: fromDrivers.reducer,
  businessesState: fromBusinesses.reducer,
};
