import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './auth.effects';
import { reducer } from './auth.state';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature('auth', reducer), EffectsModule.forFeature([AuthEffects])],
})
export class AuthStoreModule {}
