import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from '.';

export interface AuthState {
  customToken: string;
  signedInAsOperator: boolean;
  operatorUserToken: string;
}

export const initialState: AuthState = {
  customToken: null,
  signedInAsOperator: false,
  operatorUserToken: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.operatorUserCustomTokenFetched, (state, action) => ({
    ...state,
    customToken: action.customToken.token,
  })),
  on(AuthActions.operatorUserTokenChanged, (state, action) => ({
    ...state,
    operatorUserToken: action.operatorUserToken,
  })),
  on(AuthActions.signedInAsOperator, state => ({
    ...state,
    signedInAsOperator: true,
  })),
);

export function reducer(state: AuthState, action: Action) {
  return authReducer(state, action);
}
