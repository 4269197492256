import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from '../models/menu-item.model';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor() {}

  createPageTitle(route: ActivatedRoute, appName: string): string {
    let child = route.firstChild;
    if (child === null) {
      return appName;
    }
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data.title) {
      return [child.snapshot.data.title, appName].join(' - ');
    }
    return appName;
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const title = child.snapshot.data.title;
      const skipBreadcrumb = child.snapshot.data.skipBreadcrumb;
      if (title !== null && title !== '' && !skipBreadcrumb) {
        breadcrumbs.push({ title, url, id: child.snapshot.params?.id });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
