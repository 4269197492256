import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MaterialIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  registerIcons() {
    this.matIconRegistry.addSvgIcon(
      `intercom-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/intercom.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      `flag-da`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/flags/da.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      `flag-nb`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/flags/nb.svg`),
    );
  }
}
