import * as fromPartnerSettlements from './partner-settlements/store/partner-settlements.state';
import * as fromPartnerCompanies from './partner-companies/store/partner-companies.state';

import { ActionReducerMap } from '@ngrx/store';

export interface FinanceManagerState {
  partnerSettlements: fromPartnerSettlements.PartnerSettlementsState;
  partnerCompanies: fromPartnerCompanies.PartnerCompaniesState;
}

export const reducers: ActionReducerMap<FinanceManagerState> = {
  partnerSettlements: fromPartnerSettlements.partnerSettlementsReducer,
  partnerCompanies: fromPartnerCompanies.partnerCompaniesState,
};
