<mat-card class="login-component-container">
  <div class="header mat-h2">Log in to Viggo Console</div>

  <form [formGroup]="loginForm" class="login-form">
    <div class="flex-row flex-center-center">
      <mat-form-field class="field">
        <input matInput placeholder="Email" type="email" formControlName="email" />
        @if (hasError('email', 'email')) {
          <mat-error> Please enter a valid email address </mat-error>
        }
        @if (hasError('email', 'required')) {
          <mat-error> Email is <strong>required</strong> </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="flex-row flex-center-center">
      <mat-form-field class="field">
        <input matInput placeholder="Password" type="password" formControlName="password" />
        @if (hasError('password', 'required')) {
          <mat-error> Password is <strong>required</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="flex-row reset-password mat-small flex-end-start" (click)="onResetPasswordClick()">
      Forgot password?
    </div>

    <div class="flex-row flex-center-center">
      <button
        class="login-btn"
        color="primary"
        type="submit"
        mat-raised-button
        (click)="onLoginClick()"
        [disabled]="!loginForm.valid">
        Log in
      </button>
    </div>
  </form>

  <div class="flex-row flex-center-center providers-container">
    <button class="provider-btn" mat-raised-button (click)="onGoogleSigninClick()">
      <img src="assets/images/google.svg" alt="Google" />
      <span class="mat-body">Sign in with Google</span>
    </button>
  </div>
</mat-card>
