import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { AuthSelectors } from '../index';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FinanceManagerGuard {
  constructor(private store: Store<AppState>) {}

  hasFinanceManagerRole() {
    return this.store.select(AuthSelectors.isInitializedSelector).pipe(
      filter(isInitialized => isInitialized),
      switchMap(_ => this.store.select(AuthSelectors.hasFinanceManagerRoleSelector)),
      first(),
    );
  }
}
