import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from '.';

export interface AuthState {
  customToken: string;
  signedInAsB2BAccountManager: boolean;
  b2bAccountManagerToken: string;
}

export const initialState: AuthState = {
  customToken: null,
  signedInAsB2BAccountManager: false,
  b2bAccountManagerToken: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.b2bAccountManagerCustomTokenFetched, (state, action) => ({
    ...state,
    customToken: action.customToken.token,
  })),
  on(AuthActions.b2bAccountManagerUserTokenChanged, (state, action) => ({
    ...state,
    b2bAccountManagerToken: action.b2bAccountManagerToken,
  })),
  on(AuthActions.signedInAsB2BAccountManager, state => ({
    ...state,
    signedInAsB2BAccountManager: true,
  })),
);

export function reducer(state: AuthState, action: Action) {
  return authReducer(state, action);
}
